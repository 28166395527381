<template>
  <div class="container-page">
    <div>
      <p class="title-inner">Карточка участника МПК</p>
    </div>
    <div class="card-info">
      <div class="card-info__row row">
        <div class="mb-16">
          <PictureUpload v-on:pictureSelected="pictureSelected" :initialPhoto="item.picture" />
        </div>
        <div class="mb-16">
          <div class="form-group required">
            <ComponentInput
              label="Страна"
              :select2Options="countryList"
              v-model="item.countryId"
              type="select2"
              required
              :select2Settings="select2Settings"
            />
          </div>
        </div>
        <div class="mb-16">
          <div class="form-group required">
            <ComponentInput
              label="Категория"
              :select2Options="participantCategoryList"
              v-model="item.participantCategoryId"
              type="select2"
              required
              :select2Settings="select2Settings"
            />
          </div>
        </div>
        <div class="mb-16">
          <div class="form-group required">
            <ComponentInput v-model="item.name" label="ФИО" required maxLength="500" placeholder="Введите ФИО" />
          </div>
        </div>
        <div class="mb-16">
          <div class="form-group required">
            <ComponentInput
              label="Организация"
              :select2Options="organizations"
              v-model="item.organizationId"
              type="select2"
              required
              :select2Settings="select2Settings"
              placeholder="Выберите организацию"
            />
          </div>
        </div>
        <div class="mb-16">
          <div class="form-group required">
            <ComponentInput
              v-model="item.position"
              label="Должность"
              required
              maxLength="250"
              placeholder="Введите должность"
            />
          </div>
        </div>
        <div class="mb-16">
          <div class="form-group">
            <ComponentInput
              v-model="item.phone"
              label="Контактный телефон"
              maxLength="250"
              placeholder="Введите контактный телефон"
            />
          </div>
        </div>
        <div class="mb-16">
          <label class="form-label">Информация об участнике</label>
          <div class="form-group required">
            <ComponentInput v-model="item.infoName" placeholder="название" maxLength="250" />
          </div>
          <div class="form-group required">
            <ComponentInput v-model="item.infoLink" placeholder="ссылка" maxLength="500" />
          </div>
        </div>
      </div>
      <div class="card-info__row">
        <ButtonStock class="mr-8" title="Сохранить" v-on:click="save" />
        <ButtonStock title="Отмена" v-on:click="close" />
      </div>
      <!-- <div class="row mt-10">
            <div class="form-check">
                <input type="checkbox" class="form-check-input" v-model="item.isActive" id="chActive">
                <label for="chActive" class="form-check-label control-label">Запись активна</label>
            </div>
        </div> -->
    </div>
  </div>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';

  import ComponentInput from '@/common/components/ComponentInput';
  import PictureUpload from '@/common/components/PictureUpload';
  import Constants from '@/common/constants';
  import Utils from '@/common/utils';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';

  import OrgApi from '../../admin/api/organization';
  import API from '../api/participant';

  export default {
    name: 'ParticipantCard',
    components: {
      ComponentInput,
      ButtonStock,
      PictureUpload,
    },
    data() {
      return {
        id: this.$route.params.id,
        item: {
          countryId: 0,
          participantCategoryId: 0,
        },

        select2Settings: Constants.select2Settings,
        countryList: [{ id: 0, text: Constants.emptySelectOptionText, selected: true }],
        participantCategoryList: [{ id: 0, text: Constants.emptySelectOptionText, selected: true }],
        organizations: [],
      };
    },
    created() {
      this.loadCard();

      Utils.loadSelectOptions('countryList', this.countryList);
      Utils.loadSelectOptions('mpkParticipantCategoryList', this.participantCategoryList);

      const request = {
        pageNumber: 1,
        pageSize: 0,
        isActive: true,
        typeIds: [Constants.organizationType.foiv, Constants.organizationType.external],
      };
      OrgApi.searchShortFormat(request)
        .then((response) => {
          this.organizations = response.data.items.map((x) => {
            return { text: x.name, id: x.id };
          });
        })
        .catch((error) => {
          console.error(error?.response?.data);
        });
    },
    methods: {
      loadCard() {
        if (this.id > 0) {
          API.find(this.id).then((response) => {
            this.item = response.data;
          });
        } else {
          this.item = {
            isActive: true,
            countryId: 0,
            participantCategoryId: 0,
          };
        }
      },
      save() {
        this.v$.$touch();
        if (this.v$.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
          return;
        }

        this.item.participantCategory = null;
        this.item.country = null;

        API.update(this.item)
          .then((response) => {
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            if (response && response.data.id) {
              this.$router.push({ name: 'ParticipantCard', params: { id: response.data.id, action: 'edit' } });
            }
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
          });
      },
      close() {
        this.$router.push({ name: 'Participants' });
      },
      pictureSelected(data) {
        this.item.picture = data;
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        item: {},
      };
    },
  };
</script>

<style lang="scss" scoped>
  .card-info {
    max-width: 900px;
  }
</style>
